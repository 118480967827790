import Layer from '@mozaic-ds/freemarker/js/modules/_layer.js';
import { hitEventTms } from 'integration-web-core--socle/js/assets/commons/_tms';
import { cdlEvent, customerHeaderModuleCollaboratorClickEvent } from './trackingEvents';
class CustomerHeaderModule extends lm.Composant {
  constructor(id) {
    super(id);
    // Init code
  }
}
class CustomerHeaderModuleV2 extends lm.Composant {
  constructor(id) {
    super(id);
    const customerNameElement = document.querySelector('.customerheadermodule__label.connected');
    if (customerNameElement) {
      /*
       The local storage key 'customerFirstName' is set by customer-space-module when customer updates its first name
       Whenever the component is rendered in connected mode, it will not acknowledge the change of the customer firstname from the customer space application
       It causes desync that the bellow implementation is fixing
      */
      const localStorageCustomerFirstName = localStorage.getItem('customerFirstName');
      if (localStorageCustomerFirstName) {
        customerNameElement.textContent = localStorageCustomerFirstName;
      }
      customerNameElement.classList.add("kl-visible@from-l");
    } else {
      localStorage.removeItem('customerFirstName');
    }
  }
}
class ShoppingListHeaderModule extends lm.Composant {
  constructor(id) {
    super(id);
    // Init code
  }
}
lm.DOMReady(function () {
  const customerHeaderModuleComponent = document.querySelector('.component-customerheadermodule');
  if (window.ACTIVATE_LAYER === 'true') {
    const layer = new Layer({
      'classContent': 'customer-header-layer-info',
      'isScrollEdge': false
    });
    customerHeaderModuleComponent.addEventListener('mouseover', () => {
      layer.open();
    });
    const closeInfoLayer = document.querySelector('.js-close-layer-info');
    closeInfoLayer.addEventListener('click', () => {
      layer.close();
    });
  }
  const customerHeaderModuleCollaboratorLink = document.querySelector('.customerheadermodule__link.collaborator');
  if (customerHeaderModuleCollaboratorLink) {
    customerHeaderModuleCollaboratorLink.addEventListener('click', () => {
      console.log("Tracking event sent - " + JSON.stringify(customerHeaderModuleCollaboratorClickEvent));
      hitEventTms(customerHeaderModuleCollaboratorLink, cdlEvent, JSON.stringify(customerHeaderModuleCollaboratorClickEvent));
    });
  }
  const CustomerHeaderModuleModule = new CustomerHeaderModule('customerheadermodule');
  const CustomerHeaderModuleModuleV2 = new CustomerHeaderModuleV2('customerheadermodulev2');
  const ShoppingListHeaderModuleModule = new ShoppingListHeaderModule('shoppinglistheadermodule');
});